import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'scripts/modules/external-links';
import 'components/page-header/js/page-header';
import 'components/page-footer/page-footer';
import { init as adsInit } from 'components/ad/ads';
import { init as exploreHeroInit } from 'components/explore-hero/explore-hero';
import { init as exploreMoreTopicsInit } from 'components/topic/explore-more/explore-more-topics';
import { init as getUserViewingHistoryInit } from 'scripts/modules/get-user-viewing-history';
import { init as localizationEventsInit } from 'scripts/modules/localization-events';
import { init as myListButtonInit } from 'components/my-list/my-list-button/components/button-group';
import { init as newsLetterInit } from 'components/newsletter/newsletter';
import { init as photoGalleryInit } from 'components/photo-gallery/photo-gallery';
import { init as showPosterVideoRowInit } from 'components/show-poster-video-row/show-poster-video-row';
import { init as showPosterVideoRowSeasonsInit } from 'components/show-poster-video-row/show-poster-video-row-seasons';
import { init as showRowInit } from 'components/show-row/show-row';
import { init as supportingTextInit } from 'components/supporting-text/supporting-text';
import { init as topTenShowRowCarouselInit } from 'components/show-row/top-ten/top-ten';
import { init as videoRowInit } from 'components/video-row/video-row';
import { init as videoHighlightsRowInit } from 'components/video-highlights-row/video-highlights-row';

adsInit();
exploreHeroInit();
exploreMoreTopicsInit();
getUserViewingHistoryInit();
localizationEventsInit();
myListButtonInit();
newsLetterInit();
photoGalleryInit();
showPosterVideoRowInit();
showPosterVideoRowSeasonsInit();
showRowInit();
supportingTextInit();
topTenShowRowCarouselInit();
videoRowInit();
videoHighlightsRowInit();