import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

import { arrowPath } from 'components/carousel-arrows/arrow-path';

interface ExploreHeroVideosCache {
  exploreHeroVideos?: HTMLElement;
  [key: string]: Splide | HTMLElement;
}

const cache: ExploreHeroVideosCache = {};

/**
 * Caches re-used elements
 */
const setupCache = () => {
  cache.exploreHeroVideos = document.querySelector(
    '.explore-hero__splide'
  );
};

/**
 * Sets up each Explore Hero video row instance.
 */
const setupExploreHeroVideos = () => {
  if (cache.exploreHeroVideos) {
    cache[`carousel`] = new Splide(cache.exploreHeroVideos, {
      arrowPath: arrowPath,
      // remember options values are for *below* these numbers
      // (and this video row only appears below 768px widths)
      breakpoints: {
        768: {
          perPage: 2,
        },
        480: {
          perPage: 1,
        }
      },
      classes: {
        arrow: 'carousel__arrow',
        prev: 'splide__arrow--prev carousel-prev',
        next: `splide__arrow--next carousel-next`,
      },
      gap: '14px',
      pagination: false,
    }).mount();
  }
};

/**
 * Initializes component.
 */
const init = (): void => {
  setupCache();
  setupExploreHeroVideos();
};

export { init };
